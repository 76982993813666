import React, { FC } from "react";
import Link from "../components/customLink";
import { Layout } from "../components/layout";

// markup
const NotFoundPage: FC = () => (
  <Layout>
    <title>Not found</title>
    <div>Page not found</div>
    <p>
      <Link to="/">Go home</Link>.
    </p>
  </Layout>
);

export default NotFoundPage;
